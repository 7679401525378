import { DOCUMENT } from '@angular/common';
import { Directive, inject, input, OnChanges } from '@angular/core';

import { PrivaLocalizationService } from '@priva/localization';

@Directive({
    selector: '[next-throbber]',
})
export class ThrobberDirective implements OnChanges {
    private readonly localizationService = inject(PrivaLocalizationService);
    private document = inject<Document>(DOCUMENT);
    public throbbers = input<{
        [key: string]: string;
    }>();

    public active: any = {};

    public ngOnChanges() {
        const activeKeys = Object.keys(this.active);

        const newKeys = Object.keys(this.throbbers()).filter((k) => !activeKeys.includes(k));
        const updateKeys = activeKeys.filter((k) => this.throbbers()[k]);
        const removeKeys = activeKeys.filter((k) => !this.throbbers()[k]);

        for (const key of newKeys) {
            this.addThrobber(key);
        }
        for (const key of updateKeys) {
            this.updateThrobber(key);
        }
        for (const key of removeKeys) {
            this.removeThrobber(key);
        }
    }

    private addThrobber(key: string) {
        const loadingMessage = this.translateMessage(this.throbbers()[key]);
        const element = this.document.getElementsByClassName(key)[0] as HTMLElement;
        /* istanbul ignore else lvb, safety check */
        if (element) {
            element.style.position = 'relative';

            const throbber = document.createElement('div');

            throbber.className = `throbber throbber-${key}`;
            throbber.innerHTML = `<div class="loading-state text-center" data-test-id="throbber">
                                      <div class="loading-state-spinner spinner spinner-lg"></div>
                                      <div class="loading-state-description margin-top-1">
                                          ${loadingMessage}
                                      </div>
                                  </div>`;
            element.append(throbber);
            this.active[key] = throbber;
        }
    }

    private updateThrobber(key: string) {
        this.removeThrobber(key);
        this.addThrobber(key);
    }

    private removeThrobber(key: string) {
        const element = this.document.getElementsByClassName(key)[0];
        if (!element) {
            return;
        }
        try {
            element.removeChild(this.active[key]);
            delete this.active[key];
        } catch (e) {
            /* eslint-disable-next-line */
        }
    }

    private translateMessage(loadingMessageKey: string) {
        if (!loadingMessageKey) {
            return '';
        }
        return this.localizationService.instant(loadingMessageKey);
    }
}
