import { ModuleWithProviders, NgModule } from '@angular/core';

import { PrimaryNavigationService } from '@priva/appshell';

import { NuroPrimaryNavigationService } from './primary-navigation.service';

@NgModule({})
export class AppNavigationModule {
    public static forRoot(): ModuleWithProviders<AppNavigationModule> {
        return {
            ngModule: AppNavigationModule,
            providers: [{ provide: PrimaryNavigationService, useClass: NuroPrimaryNavigationService }],
        };
    }
}
