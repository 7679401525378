import { DragDropModule } from '@angular/cdk/drag-drop';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppShellUtilitiesModule } from '@priva/appshell';
import { PrivaAuthCurrentUserBffModule } from '@priva/auth/current-user';
import { PrivaAuthHttpBffModule, PrivaAuthHttpOptions } from '@priva/auth/http';
import { AppScreenSizeDetectorModule } from '@priva/components-candidates/app-screen-size-detector';
import { FLOORPLAN_BASEURL_TOKEN } from '@priva/components-candidates/floorplan';
import { PrivaNotificationsModule } from '@priva/components/notifications';
import { PrivaErrorPageHandlerService } from '@priva/error-pages';
import {
    PrivaLocalizationModule,
    PrivaLocalizationOptions,
    VersionBasedTranslationLoader,
} from '@priva/localization';
import { PrivaScrollbarModule } from '@priva/utilities/scrollbar';
import { PrivaScrollToModule } from '@priva/utilities/scrollto';
import { PrivaToggleModule } from '@priva/utilities/toggle';
import { PrivaWindowEventsModule } from '@priva/utilities/window-events';

import { NextThrobberModule } from '@next/design-system/throbber';
import { StatusErrorPageModule } from 'app/common/error-handling';
import { OfflineSyncModule } from 'app/common/offline-sync';
import { AppConfigurationModule, AppConfigurationService } from 'app/configuration';
import { ConsoleModule } from 'app/core/console';
import { httpInterceptorProviders } from 'app/core/interceptors';
import { AppNavigationModule } from 'app/core/navigation';
import { DuplicateModule } from 'app/shared/duplicate/duplicate.module';
import { UndoRedoModule } from 'app/shared/undo/undo-redo.module';

import { environment } from '../environments/environment';
import { AppMainModule } from './app-main.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppStateModule } from './state/app-state.module';
import { PlatformService } from './state/platform.service';

export function getCustomAuthOptions(_configService: AppConfigurationService): PrivaAuthHttpOptions {
    const options = new PrivaAuthHttpOptions();
    options.bffConfig = { csrfValue: undefined };

    return options;
}

function getCustomLocalizationOptions(): PrivaLocalizationOptions {
    const options = new PrivaLocalizationOptions();
    options.path = 'assets/translations';
    options.version = environment.version;
    options.availableLanguagesResource = 'assets/translations/locales.json';
    options.defaultLanguage = 'en-US';
    return options;
}

function getVersionBasedTranslationLoader(
    http: HttpClient,
    options: PrivaLocalizationOptions,
): VersionBasedTranslationLoader {
    return new VersionBasedTranslationLoader(http, options);
}

export const initializeAppFactory = (platformService: PlatformService) => () => platformService.initialize();

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,

        AppConfigurationModule.forRoot(),
        PrivaAuthCurrentUserBffModule.forRoot(),
        PrivaAuthHttpBffModule.forRoot({
            provide: PrivaAuthHttpOptions,
            useFactory: getCustomAuthOptions,
            deps: [AppConfigurationService],
        }),

        AppNavigationModule.forRoot(),
        PrivaWindowEventsModule.forRoot(),
        PrivaScrollbarModule.forRoot(),
        PrivaToggleModule.forRoot(),
        PrivaNotificationsModule.forRoot(),
        PrivaScrollToModule.forRoot(),
        PrivaLocalizationModule.forRoot({
            provide: PrivaLocalizationOptions,
            useFactory: getCustomLocalizationOptions,
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: getVersionBasedTranslationLoader,
                deps: [HttpClient, PrivaLocalizationOptions],
            },
        }),

        AppRoutingModule,
        AppShellUtilitiesModule,
        AppMainModule,
        AppScreenSizeDetectorModule,
        AppStateModule,

        NextThrobberModule,
        UndoRedoModule,
        DuplicateModule,
        DragDropModule,
        StatusErrorPageModule,

        OfflineSyncModule,
        // Needed to prevent a token injection error when used in a lazy module
        // https://stackoverflow.com/questions/64804090/runtime-error-ng-select-selection-model
        NgSelectModule,
        ConsoleModule,
    ],
    providers: [
        PlatformService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppFactory,
            deps: [PlatformService],
            multi: true,
        },
        AppConfigurationService,
        PrivaErrorPageHandlerService,
        provideHttpClient(withInterceptorsFromDi()),
        httpInterceptorProviders,
        { provide: APP_BASE_HREF, useValue: '/' },
        {
            provide: FLOORPLAN_BASEURL_TOKEN,
            useValue: environment.configuration.uris.floorplanApiUrl,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
