import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ContentTypeInterceptor } from './content-type.interceptor';
import { DelayHttpInterceptor } from './delay.interceptor';
import { UndoRedoInterceptor } from './undo-redo.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: ContentTypeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DelayHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UndoRedoInterceptor, multi: true },
];
