import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PrivaBffLoggedInUserGuard } from '@priva/auth/current-user';
import { PrivaErrorRouteUrls } from '@priva/error-pages';
import { PrivaBrowserCheckGuard, PrivaUtilitiesModule } from '@priva/utilities';

import { AppMainComponent } from './app-main.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [PrivaBffLoggedInUserGuard],
        children: [
            {
                path: 'signout',
                loadChildren: async () => (await import('./core/routing/sign-out.module')).AppSignOutModule,
            },
            {
                path: PrivaErrorRouteUrls.Base,
                loadChildren: async () =>
                    (await import('./core/routing/error-pages.module')).AppErrorPagesModule,
            },
            {
                path: 'solutions',
                canActivate: [PrivaBrowserCheckGuard],
                component: AppMainComponent,
                children: [
                    {
                        path: '',
                        loadChildren: async () =>
                            (await import('./solutions/solutions.module')).SolutionsModule,
                    },
                ],
            },
            { path: '**', redirectTo: 'solutions', pathMatch: 'full' },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), PrivaUtilitiesModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
