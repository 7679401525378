import { Pipe, PipeTransform, signal, Type } from '@angular/core';

import { DynamicComponent } from '@priva/components/dynamic';

const metadataKey = 'ɵcmp';
// Checks whether the given component input is declared using (1) a new signal input or (2) the old Input annotation
const isSignalInput = (component: Type<any>, input: string) =>
    Array.isArray(component[metadataKey].inputs[input]);

@Pipe({
    name: 'toInputSignals',
    standalone: true,
})
// Temporal solution to let the Dynamic Component Slot work with the old Input() annotation and new input() signal
// When all annotation-based inputs are refactored out, this code is no longer needed
export class ToInputSignalsPipe implements PipeTransform {
    transform(component: DynamicComponent | undefined): DynamicComponent | undefined {
        if (!component?.inputs) {
            return component;
        }
        const newComponent: DynamicComponent = { inputs: {}, component: component.component };
        Object.entries(component.inputs).forEach(([input, value]) => {
            if (isSignalInput(component.component, input)) {
                newComponent.inputs[input] = signal(value);
            } else {
                newComponent.inputs[input] = value;
            }
        });
        return newComponent;
    }
}
