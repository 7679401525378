import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

import { PrimaryNavigationService, PrivaNavigationItem } from '@priva/appshell';

@Injectable()
export class NuroPrimaryNavigationService implements PrimaryNavigationService {
    private readonly navigation$: Subject<PrivaNavigationItem[]> = new ReplaySubject();

    public get navigation(): Subject<PrivaNavigationItem[]> {
        return this.navigation$;
    }
}
