import { Component, input, OnInit } from '@angular/core';

/** @dynamic */
@Component({
    selector: 'priva-screen-size-detector',
    templateUrl: './dialog-screen-size-exceed.component.html',
})
export class DialogUndercutScreenLimitComponent implements OnInit {
    public width = input<number>();
    public translateParams: object | undefined;

    public ngOnInit(): void {
        this.translateParams = { width: this.width() };
    }
}
