import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UndoRedoInterceptor implements HttpInterceptor {
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let newRequest = req;

        if (req.body?.isUndo) {
            const newBody = req.body;
            delete newBody.isUndo;
            newRequest = newRequest.clone({
                headers: newRequest.headers.set('x-undo', 'true'),
                body: newBody,
            });
        }

        return next.handle(newRequest);
    }
}
