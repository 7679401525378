import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { NotifyingSet } from 'app/core/utils';
import { AppActions, AppStateContainer } from 'app/state';

const durationThresholdMs = 500;
const ipv4Pattern = /(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})/g;

@Injectable()
export class DelayHttpInterceptor implements HttpInterceptor {
    private readonly store$ = inject<Store<AppStateContainer>>(Store);
    private readonly httpDelay: Signal<boolean> = this.store$.selectSignal((s) => s.app.httpDelay);
    private readonly onEmpty = () => {
        if (this.httpDelay()) {
            this.store$.dispatch(AppActions.clearHttpDelay());
        }
    };
    private readonly onFirstAdd = () => this.store$.dispatch(AppActions.showHttpDelay());
    private readonly delayedUrls = new NotifyingSet<string>({
        onFirstAdd: this.onFirstAdd,
        onEmpty: this.onEmpty,
    });

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (this.isIpzcUrl(request.url)) {
            return next.handle(request);
        }

        const requestTimout = setTimeout(
            () => this.delayedUrls.add(request.urlWithParams),
            durationThresholdMs,
        );
        return next.handle(request).pipe(
            finalize(() => {
                this.delayedUrls.tryRemove(request.urlWithParams);
                clearTimeout(requestTimout);
            }),
        );
    }

    // TODO: use an HTTP header (e.g. a custom X-header) to whitelist requests that should not be monitored
    private isIpzcUrl(url: string): boolean {
        return ipv4Pattern.exec(url) !== null;
    }
}
